<script setup>
    import ApplyFilter from 'o365.vue.components.ApplyFilter.vue';
    import FilterEditor from 'o365.vue.components.FilterEditor.vue';
    
    import { defineProps} from "vue";
 
    const props = defineProps(['filterObject','columnName','filterItem','dropdown','hideOperators']);
</script>
<template>
    <FilterEditor :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown" :hideOperators="hideOperators"></FilterEditor>

    <ApplyFilter :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown" class="mt-2"></ApplyFilter>
</template>